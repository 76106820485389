<template>
  <canvas ref="heroLightpass"></canvas>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  name: "ScrollImageAnimation",
  setup() {
    const heroLightpass = ref(null);
    let context;
    const frameCount = 300;
    let isScrolling;
    let targetFrameIndex = 0;
    let currentFrameIndex = 0;
    let animating = false;
    const fps = 24;
    const frameDuration = 1000 / fps;
    let lastFrameTime = 0;
    let preloadedImages = [];
    let loadedFrames = 0;
    let firstFrameLoaded = false;

    const currentFrame = (index, isCompressed) =>
      isCompressed
        ? `./scroller-animation/tko1_compressed/T_0${index
            .toString()
            .padStart(4, "0")}.webp`
        : `./scroller-animation/tko1/T_0${index
            .toString()
            .padStart(4, "0")}.webp`;

    const preloadImages = async () => {
      const batchSize = 10;
      for (let i = 0; i <= frameCount; i += batchSize) {
        const batch = [];
        for (let j = 0; j < batchSize && i + j <= frameCount; j++) {
          const index = i + j;
          batch.push(loadImage(index));
        }
        await Promise.all(batch);
        // Start animation as soon as the first batch is loaded
        if (i === 0 && !animating) {
          // animating = true;
          // requestAnimationFrame(animateToFrame);
        }
      }
    };

    const loadImage = (index) => {
      return new Promise((resolve) => {
        const compressedImg = new Image();
        compressedImg.src = currentFrame(index, true);
        compressedImg.onload = () => {
          if (!firstFrameLoaded && index === 1) {
            updateImage(compressedImg);
            firstFrameLoaded = true;
          }
          loadedFrames++;

          // Load full resolution image
          const fullResImg = new Image();
          fullResImg.src = currentFrame(index, false);
          fullResImg.onload = () => {
            preloadedImages[index] = fullResImg;

            //display current loaded full res image index
            // console.log(index);

            //display current frame index
            // console.log(Math.round(currentFrameIndex));

            // If this frame is currently being displayed, update it to full res
            if (Math.round(currentFrameIndex) === index) {
              updateImage(fullResImg);
            }
            resolve();
          };
        };
      });
    };

    const updateImage = (img) => {
      if (img && img.complete && context) {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        // Scale the image to fit the canvas
        const scale = Math.max(
          context.canvas.width / img.width,
          context.canvas.height / img.height
        );
        const x = (context.canvas.width - img.width * scale) / 2;
        const y = (context.canvas.height - img.height * scale) / 2;
        context.drawImage(img, x, y, img.width * scale, img.height * scale);
      }
    };

    const easeInOutSine = (t) => (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2;

    const animateToFrame = (timestamp) => {
      if (!animating) return;

      if (timestamp - lastFrameTime < frameDuration) {
        requestAnimationFrame(animateToFrame);
        return;
      }

      lastFrameTime = timestamp;

      const frameDifference = targetFrameIndex - currentFrameIndex;
      if (Math.abs(frameDifference) < 0.01) {
        animating = false;
        return;
      }
      currentFrameIndex += frameDifference * 0.2;
      let roundedCurrentFrameIndex = Math.round(currentFrameIndex);

      // Only update to frames that have been loaded
      if (
        roundedCurrentFrameIndex <= loadedFrames &&
        preloadedImages[roundedCurrentFrameIndex]
      ) {
        updateImage(preloadedImages[roundedCurrentFrameIndex]);
      }

      requestAnimationFrame(animateToFrame);
    };

    const handleScroll = () => {
      clearTimeout(isScrolling);
      const scrollTop = document.documentElement.scrollTop;
      const maxScrollTop =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollFraction = scrollTop / maxScrollTop;
      targetFrameIndex = Math.min(
        frameCount - 1,
        Math.ceil(easeInOutSine(scrollFraction) * frameCount)
      );

      if (!animating) {
        animating = true;
        requestAnimationFrame(animateToFrame);
      }

      isScrolling = setTimeout(() => {
        animating = false;
      }, 200);
    };

    onMounted(() => {
      const canvas = heroLightpass.value;
      context = canvas.getContext("2d");
      canvas.width = 1920;
      canvas.height = 1080;
      preloadImages();
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      heroLightpass,
    };
  },
};
</script>

<style scoped>
html {
  height: 100%;
}

body {
  height: 1000%;
  background: #000;
}

canvas {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
</style>