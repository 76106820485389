<template>
  <div class="app">
    <HeaderBar />
    <NavBar :show="showNavBar" />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <!-- this will cause crazy bug if the component has multiple node -->
        <component :is="Component" :panel-type="currentPanel" />
      </transition>
    </router-view>

  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import HeaderBar from "./components/HeaderBar.vue";
import NavBar from "./components/NavBar.vue";
import SidePanelInfo from "./components/SidePanelInfo.vue";
import SidePanelEIA from "./components/SidePanelEIA.vue";

export default {
  components: {
    HeaderBar,
    NavBar,
    SidePanelInfo,
    SidePanelEIA,
  },
  setup() {
    const ProjectInfoScrollPosition = 200;
    const isScrolling = ref(false);
    const isViaScroll = ref(false);
    const route = useRoute();
    const router = useRouter(); // Use useRouter for programmatic navigation
    // const showNavBar = ref(false);
    const scrollPosition = ref(0);

    const currentPanel = computed(() => {
      if (route.path === "/project-info") {
        return "projectInfo";
        // } else if (route.path === "/" && route.query.info === "project-info") {
        //   return "projectInfo";
      } else if (route.name === "EIA") {
        return "environmentalImpacts";
      }
      return null;
    });

    const showNavBar = computed(() => route.name !== "Home");

    let scrollTimeout;

    const handleScroll = () => {
      scrollPosition.value = window.scrollY;
      // isScrolling.value = true;
      // clearTimeout(scrollTimeout);
      // scrollTimeout = setTimeout(() => {
      //   isScrolling.value = false;
      // }, 100);

      // Navigate to "Home" when on "ProjectInfo" and scrolled to <100
      if (
        route.name === "ProjectInfo" &&
        scrollPosition.value < ProjectInfoScrollPosition
      ) {
        router.push({ name: "Home" });
      }
      // Navigate to "ProjectInfo" when on "Home" and scrolled to >500
      else if (
        route.name === "Home" &&
        scrollPosition.value >= ProjectInfoScrollPosition
      ) {
        isViaScroll.value = true;
        console.log("enter ProjectInfo" + isViaScroll.value);
        router.push({ name: "ProjectInfo" });
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    // Centralized scroll to position function
    const scrollToPosition = (position) => {
      console.log("scroll to position" + position);
      window.scrollTo({ top: position });
    };

    // Use Vue Router's afterEach hook for scroll adjustments
    router.afterEach((to, from) => {
      if (to.name === "ProjectInfo" && !isViaScroll.value) {
        // Delay scroll to ensure the page is rendered
        console.log("scroll to ProjectInfo" + isViaScroll.value);
        // setTimeout(() => scrollToPosition(ProjectInfoScrollPosition), 500);
      } else if (to.name === "EIA") {
        scrollToPosition(0);
      } else if (isViaScroll) {
        isViaScroll.value = false;
      }
    });

    return {
      showNavBar,
      currentPanel,
      scrollPosition,
      isScrolling,
      isViaScroll,
    };
  },
};
</script>

<style>
html {
  /* scrollbar-gutter: stable;  */
  /* reserves space when scrolling is locked */
  /* scrollbar-width: thin;  */
  /* makes scrollbar thinner. Wouldn't set it to `none` because of accessibility reasons */
  /* scrollbar-color: var(--primary-color) var(--bg-color); */
  /* custom colors makes scrollbar blend in  */
  scroll-behavior: smooth !important;
}



* {
  margin: 0;
  padding: 0;
  border: none;
}
.app {
  height: 100vh !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* ::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #008768;
}

::-webkit-scrollbar-thumb:hover {
  background: #008768;
} */

/* For webkit browsers (Chrome, Safari) */
html,
body {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/* Hide scroll bar for webkit browsers */
html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}


</style>